import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import "./about-hero.css";

const HeroSection = () => {
  return (
    <section className="hero-section">
      <Container>
        <Row className="align-items-center">
          <Col md={6}>
            <h3>We are Innkeeper</h3>
            <p>
              We are a digital inn on a mission to provide upskilling
              opportunities for in-demand tech roles for African talent.
            </p>
            <Button variant="primary" className="contact-button">
              Contact Us
            </Button>
          </Col>
          <Col md={6}>
            <img
              src="https://res.cloudinary.com/dilc7oqpu/image/upload/v1727444489/Innkeeper%20App/about-page/aboutus.jpg"
              alt="Innkeeper Hero"
              className="img-fluid"
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default HeroSection;
