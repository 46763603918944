import React from "react";
import "./whychooseitem.css";
const WhyChooseUsCard = ({ title, description, icon, arrow }) => {
  return (
    <>
      <div className="featureCard p-4 mb-4">
        <img src={icon} alt={`${title} icon`} className="icon pb-3" />
        <h5 className="title pb-3">{title}</h5>
        <p className="description">{description}</p>
        <div className="arrowlearnmore">
          <a href="/" className="learnMore">
            Learn more
            <img src={arrow} alt="arrow icon" className="arrow" />
          </a>
        </div>
      </div>
    </>
  );
};

export default WhyChooseUsCard;
