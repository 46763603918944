import React from "react";

const NoPage = () => {
  return (
    <>
      <div>Error 404 page</div>
    </>
  );
};

export default NoPage;
