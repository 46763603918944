// AmazingMoments.js
import React from "react";
import { Container } from "react-bootstrap";

const AmazingMoments = ({ title, description, videoSrc, videoTitle }) => {
  return (
    <section className="amazing-moments-section">
      <Container className="text-center my-5">
        <h3>{title}</h3>
        <p>{description}</p>
        <iframe
          width="853"
          height="480"
          src={videoSrc}
          title={videoTitle}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
        ></iframe>
      </Container>
    </section>
  );
};

export default AmazingMoments;
