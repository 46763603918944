import React, { useState } from "react";
import {
  Navbar as BootstrapNavbar,
  Navbar,
  Nav,
  NavDropdown,
  Container,
  Button,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import menuIcon from "../assets/images/menu-icon.svg";
import cancelIcon from "../assets/images/menu-cancel-icon.svg";
import "../assets/css/navbar.css";

const TopNavbar = () => {
  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => setExpanded(!expanded);

  const handleSelect = () => {
    setExpanded(false);
  };

  return (
    <Navbar
      expand="lg"
      bg="transparent"
      variant="light"
      expanded={expanded}
      className={expanded ? "navbar-overlay" : ""}
    >
      <Container>
        <Navbar.Brand as={Link} to="/">
          <img
            src="https://res.cloudinary.com/dilc7oqpu/image/upload/v1721730217/Innkeeper%20App/about-page/logo.png"
            alt="innkeeper-logo"
            className="logo-size"
          />
        </Navbar.Brand>
        <BootstrapNavbar.Toggle
          aria-controls="navbar-nav"
          className="custom-toggler"
          onClick={handleToggle}
        >
          <img
            src={expanded ? cancelIcon : menuIcon}
            alt="Menu"
            style={{ width: "24px", height: "24px" }}
            className="menu-icon"
          />
        </BootstrapNavbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav
            className="d-flex justify-content-end w-100"
            onSelect={handleSelect}
            activeKey="/home"
          >
            <Nav.Item>
              <Nav.Link as={Link} to="/" className="mr-3">
                Home
              </Nav.Link>
            </Nav.Item>
            <NavDropdown
              title="Courses"
              id="basic-nav-dropdown"
              className="mr-3 custom-dropdown-toggle"
            >
              <NavDropdown.Item as={Link} to="/courses/frontend-development">
                Frontend Development
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/courses/data-analysis">
                Data Analysis
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/courses/cyber-security">
                Cyber Security
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/courses/data-science">
                Data Science
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/courses/devops">
                DevOps
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/courses/digital-marketing">
                Digital Marketing
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/courses/product-design">
                Product Design
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/courses/product-management">
                Product Management
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Item>
              <Nav.Link as={Link} to="/about" className="mr-3">
                About Us
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Button as={Link} to="/apply-now" className="mr-3 apply-here-btn">
                Apply here
              </Button>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default TopNavbar;
