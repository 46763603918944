import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const TalentsCompany = () => {
  const companyLogos = [
    "https://res.cloudinary.com/dilc7oqpu/image/upload/v1721681526/Innkeeper%20App/home-page/Rectangle_16_ngpmlf.svg",
    "https://res.cloudinary.com/dilc7oqpu/image/upload/v1721681526/Innkeeper%20App/home-page/Rectangle_19_pqvgqr.svg",
    "https://res.cloudinary.com/dilc7oqpu/image/upload/v1721681525/Innkeeper%20App/home-page/Rectangle_20_jzvbri.svg",
    "https://res.cloudinary.com/dilc7oqpu/image/upload/v1721681526/Innkeeper%20App/home-page/Rectangle_18_nfrom6.svg",
    "https://res.cloudinary.com/dilc7oqpu/image/upload/v1721681501/Innkeeper%20App/home-page/Rectangle_27_1_ytknli.svg",
    "https://res.cloudinary.com/dilc7oqpu/image/upload/v1721681499/Innkeeper%20App/home-page/Rectangle_28_1_j7uqdq.svg",
    "https://res.cloudinary.com/dilc7oqpu/image/upload/v1721681503/Innkeeper%20App/home-page/Rectangle_27_beizx8.svg",
  ];

  return (
    <div style={{ backgroundColor: "#E4F5FF", padding: "60px 0" }}>
      <Container>
        <Row className="text-left mb-4">
          <Col>
            <h2>Amazing Companies Our Talents Have Worked With</h2>
            <p>
              Some of the companies that have worked with our talents and have
              given amazing feedback
            </p>
          </Col>
        </Row>

        {/* Static Display on Large Screens (All logos in a straight line) */}
        <Row className="d-none d-md-flex justify-content-around align-items-center">
          <marquee behavior="scroll" direction="left" scrollamount="6">
            {companyLogos.map((logo, idx) => (
              <img
                key={idx}
                src={logo}
                alt={`Company ${idx + 1}`}
                style={{
                  maxHeight: "60px",
                  objectFit: "contain",
                  margin: "0 20px",
                }}
                className="img-fluid"
              />
            ))}
          </marquee>
        </Row>

        {/* Marquee on Small Screens */}
        <Row className="d-md-none justify-content-center">
          <marquee behavior="scroll" direction="left" scrollamount="6">
            {companyLogos.map((logo, idx) => (
              <img
                key={idx}
                src={logo}
                alt={`Company ${idx + 1}`}
                style={{
                  maxHeight: "60px",
                  objectFit: "contain",
                  margin: "0 20px",
                }}
                className="img-fluid"
              />
            ))}
          </marquee>
        </Row>
      </Container>
    </div>
  );
};

export default TalentsCompany;
