import React from "react";
import Navbar from "../../components/TopNavbar";
import Hero from "../../components/Courses/Hero";
import LearningObjectives from "../../components/Courses/LearningObjectives";
import AmazingMoments from "../../components/Courses/AmazingMoment";
import Pricing from "../../components/Courses/PaymentCard";
import Footer from "../../components/Footer";

const Frontend = () => {
  const webDevelopmentObjectives = [
    "Learn the fundamentals of HTML, CSS, and JavaScript.",
    "Build responsive web pages using modern CSS frameworks.",
    "Understand the basics of version control with Git.",
    "Create dynamic web applications using React.",
    "Implement backend services using Node.js and Express.",
    "Deploy web applications to cloud platforms.",
  ];

  const webDevelopmentPlans = [
    {
      title: "Best Value",
      description: "One-time payment",
      price: "₦250,000",
      features: [
        "Access to all classes",
        "Access to facilitators live classes",
        "Learn and get internship slots immediately",
        "Get mentorship for three months",
        "Learn in a conclusive environment",
      ],
      buttonStyle: { backgroundColor: "#002CB0", border: "none" },
    },
    {
      title: "Flexible Plan",
      description: "Pay in batches",
      price: "₦130,000 x 2",
      features: [
        "Payment in the 1st and 7th week",
        "Access to facilitators live classes",
        "Learn and get internship slots immediately",
        "Get mentorship for three months",
        "Learn in a conclusive environment",
      ],
      buttonStyle: {
        backgroundColor: "transparent",
        border: "1px solid #002CB0",
        color: "#002CB0",
      },
    },
    {
      title: "Flexible Plan",
      description: "Pay in batches",
      price: "₦100,000 x 3",
      features: [
        "Payment in the 1st, 5th and 10th week",
        "Access to facilitators live classes",
        "Learn and get internship slots immediately",
        "Get mentorship for three months",
        "Learn in a conclusive environment",
      ],
      buttonStyle: {
        backgroundColor: "transparent",
        border: "1px solid #002CB0",
        color: "#002CB0",
      },
    },
    {
      title: "Learn Now Pay Later",
      description: "Start paying a month after training",
      price: "Free",
      features: [
        "₦10,000 Admission Fee",
        "6 months repayment + 25% interest",
        "12 months repayment + 35% interest",
        "Access to facilitators live classes",
        "Get internship placements",
        "Get mentorship for three months",
        "Learn in a conclusive environment",
      ],
      buttonStyle: { backgroundColor: "#002CB0", border: "none" },
    },
  ];
  return (
    <>
      <Navbar />
      {/* Hero Component */}
      <Hero
        title="Learn  in 6 months"
        fancy="Web Development"
        description="This course teaches you the fundamentals of web development, including HTML, CSS, JavaScript, and more."
        buttonText="Enroll now"
        buttonStyle={{ backgroundColor: "#002CB0" }}
        imageSrc="https://res.cloudinary.com/dilc7oqpu/image/upload/v1727972314/Innkeeper%20App/courses/fwd.jpg"
        imageAlt="Web Development"
      />
      {/* Learning Objectives Component */}
      <LearningObjectives
        title="Web Development Objectives"
        objectives={webDevelopmentObjectives}
        imageSrc="https://res.cloudinary.com/dilc7oqpu/image/upload/v1723208259/Innkeeper%20App/courses/learning-objective-img_dlz5vo.png"
        imageAlt="Web Development Objectives"
        iconSrc="https://res.cloudinary.com/dilc7oqpu/image/upload/v1723215832/Innkeeper%20App/courses/tick-circle_l1i52j.svg"
      />
      {/* Amazing moments components */}
      <AmazingMoments
        title="Join our web development journey"
        description="Explore the exciting world of web development with our expert instructors and fellow students."
        videoSrc="https://www.youtube.com/embed/rkP42Wz7hMI"
        videoTitle="Web Development Journey"
      />

      {/* Pricing Plans */}
      <Pricing
        title="Web Development Payment Plans"
        description="Choose the plan that suits your needs"
        plans={webDevelopmentPlans}
        iconSrc="https://res.cloudinary.com/dilc7oqpu/image/upload/v1723215832/Innkeeper%20App/courses/tick-circle_l1i52j.svg"
      />
      <Footer />
    </>
  );
};

export default Frontend;
