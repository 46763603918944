import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./partners.css";

const Partners = () => {
  const logos = [
    {
      src: "https://res.cloudinary.com/dilc7oqpu/image/upload/v1721681526/Innkeeper%20App/home-page/Rectangle_16_ngpmlf.svg",
      alt: "8thGear",
    },
    {
      src: "https://res.cloudinary.com/dilc7oqpu/image/upload/v1721681526/Innkeeper%20App/home-page/Rectangle_29_zroqne.svg",
      alt: "pledges",
    },
    {
      src: "https://res.cloudinary.com/dilc7oqpu/image/upload/v1721681526/Innkeeper%20App/home-page/Rectangle_18_nfrom6.svg",
      alt: "digiplus",
    },
    {
      src: "https://res.cloudinary.com/dilc7oqpu/image/upload/v1721681526/Innkeeper%20App/home-page/Rectangle_19_pqvgqr.svg",
      alt: "havens",
    },
    {
      src: "https://res.cloudinary.com/dilc7oqpu/image/upload/v1721681525/Innkeeper%20App/home-page/Rectangle_20_jzvbri.svg",
      alt: "legalmo",
    },
    {
      src: "https://res.cloudinary.com/dilc7oqpu/image/upload/v1721681525/Innkeeper%20App/home-page/Rectangle_21_pumefm.svg",
      alt: "microsoft",
    },
    {
      src: "https://res.cloudinary.com/dilc7oqpu/image/upload/v1721681505/Innkeeper%20App/home-page/Rectangle_22_f3cs0e.svg",
      alt: "cisco",
    },
    {
      src: "https://res.cloudinary.com/dilc7oqpu/image/upload/v1721681504/Innkeeper%20App/home-page/Rectangle_24_rj1ahz.svg",
      alt: "giz",
    },
    {
      src: "https://res.cloudinary.com/dilc7oqpu/image/upload/v1721681504/Innkeeper%20App/home-page/Rectangle_25_tc0rlk.svg",
      alt: "LSETF",
    },
    {
      src: "https://res.cloudinary.com/dilc7oqpu/image/upload/v1721681503/Innkeeper%20App/home-page/Rectangle_26_frv46d.svg",
      alt: "lagos innovate",
    },
    {
      src: "https://res.cloudinary.com/dilc7oqpu/image/upload/v1721681503/Innkeeper%20App/home-page/Rectangle_27_beizx8.svg",
      alt: "trulance",
    },
    {
      src: "https://res.cloudinary.com/dilc7oqpu/image/upload/v1721681503/Innkeeper%20App/home-page/Rectangle_28_ctzulg.svg",
      alt: "Lagos state",
    },
    {
      src: "https://res.cloudinary.com/dilc7oqpu/image/upload/v1721681503/Innkeeper%20App/home-page/Rectangle_23_x8l58a.svg",
      alt: "ITF",
    },
    {
      src: "https://res.cloudinary.com/dilc7oqpu/image/upload/v1721681501/Innkeeper%20App/home-page/Rectangle_30_wzbla5.svg",
      alt: "USADF",
    },
  ];

  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration
      easing: "ease-in-out", // Easing function
      once: false, // Whether animation should happen only once
    });
  }, []);

  return (
    <div
      className="container-fluid py-5"
      style={{ backgroundColor: "#E4F5FF" }}
    >
      <div className="container first">
        <h4 className="">Our valued partners</h4>
        <p className="pb-5">
          Explore the companies shaping the future with Innkeeper.
        </p>

        <div className="logos">
          {logos.map((logo, index) => (
            <div className="logo" key={index} data-aos="fade-up">
              <img src={logo.src} alt={logo.alt} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Partners;
