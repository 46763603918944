import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./pricing.css";

const LearningObjectives = ({
  title,
  objectives,
  imageSrc,
  imageAlt,
  iconSrc,
}) => {
  return (
    <section className="pricing-section">
      <Container className="my-5">
        <Row>
          <Col md={6}>
            <img src={imageSrc} alt={imageAlt} className="img-fluid" />
          </Col>
          <Col md={6}>
            <h4>{title}</h4>
            <ul>
              {objectives.map((objective, index) => (
                <li key={index}>
                  <img src={iconSrc} alt="Tick" className="list-icon" />
                  {objective}
                </li>
              ))}
            </ul>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default LearningObjectives;
