import React from "react";
import Navbar from "../../components/TopNavbar";
import Hero from "../../components/Courses/Hero";
import LearningObjectives from "../../components/Courses/LearningObjectives";
import AmazingMoments from "../../components/Courses/AmazingMoment";
import Pricing from "../../components/Courses/PaymentCard";
import Footer from "../../components/Footer";

const dataScienceObjectives = [
  "Understand the core principles of data science.",
  "Learn to collect, clean, and analyze data.",
  "Master data visualization techniques.",
  "Gain proficiency in tools like Python, R, and SQL.",
  "Conduct statistical analysis and interpret results.",
  "Build and evaluate machine learning models.",
  "Prepare for industry-recognized data science certifications.",
];

const dataSciencePlans = [
  {
    title: "Best Value",
    description: "One-time payment",
    price: "₦250,000",
    features: [
      "Access to all classes",
      "Access to facilitators live classes",
      "Learn and get internship slots immediately",
      "Get mentorship for three months",
      "Learn in a conclusive environment",
    ],
    buttonStyle: { backgroundColor: "#002CB0", border: "none" },
  },
  {
    title: "Flexible Plan",
    description: "Pay in batches",
    price: "₦130,000 x 2",
    features: [
      "Payment in the 1st and 7th week",
      "Access to facilitators live classes",
      "Learn and get internship slots immediately",
      "Get mentorship for three months",
      "Learn in a conclusive environment",
    ],
    buttonStyle: {
      backgroundColor: "transparent",
      border: "1px solid #002CB0",
      color: "#002CB0",
    },
  },
  {
    title: "Flexible Plan",
    description: "Pay in batches",
    price: "₦100,000 x 3",
    features: [
      "Payment in the 1st, 5th and 10th week",
      "Access to facilitators live classes",
      "Learn and get internship slots immediately",
      "Get mentorship for three months",
      "Learn in a conclusive environment",
    ],
    buttonStyle: {
      backgroundColor: "transparent",
      border: "1px solid #002CB0",
      color: "#002CB0",
    },
  },
  {
    title: "Learn Now Pay Later",
    description: "Start paying a month after training",
    price: "Free",
    features: [
      "₦10,000 Admission Fee",
      "6 months repayment + 25% interest",
      "12 months repayment + 35% interest",
      "Access to facilitators live classes",
      "Get internship placements",
      "Get mentorship for three months",
      "Learn in a conclusive environment",
    ],
    buttonStyle: { backgroundColor: "#002CB0", border: "none" },
  },
];

const DataScience = () => {
  return (
    <>
      <Navbar />
      {/* Hero Component */}
      <Hero
        title="Learn"
        fancy="Data Science"
        description="This course teaches you the fundamentals of data science, including data collection, cleaning, analysis, visualization, and machine learning."
        buttonText="Enroll now"
        buttonStyle={{ backgroundColor: "#002CB0" }}
        imageSrc="https://res.cloudinary.com/dilc7oqpu/image/upload/v1723207416/Innkeeper%20App/courses/data-science-course.png"
        imageAlt="Data Science"
      />

      {/* Learning Objectives Component */}
      <LearningObjectives
        title="Data Science Objectives"
        objectives={dataScienceObjectives}
        imageSrc="https://res.cloudinary.com/dilc7oqpu/image/upload/v1723208259/Innkeeper%20App/courses/learning-objective-img_dlz5vo.png"
        imageAlt="Data Science Objectives"
        iconSrc="https://res.cloudinary.com/dilc7oqpu/image/upload/v1723215832/Innkeeper%20App/courses/tick-circle_l1i52j.svg"
      />

      {/* Amazing moments components */}
      <AmazingMoments
        title="Join our data science journey"
        description="Explore the exciting world of data science with our expert instructors and fellow students."
        videoSrc="https://www.youtube.com/embed/someDataScienceVideoId"
        videoTitle="Data Science Journey"
      />

      {/* Pricing Plans */}
      <Pricing
        title="Data Science Payment Plans"
        description="Choose the plan that suits your needs"
        plans={dataSciencePlans}
        iconSrc="https://res.cloudinary.com/dilc7oqpu/image/upload/v1723215832/Innkeeper%20App/courses/tick-circle_l1i52j.svg"
      />
      <Footer />
    </>
  );
};

export default DataScience;
