import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Card } from "react-bootstrap";
import "./hero.css";

const HeroSection = () => {
  const texts = ["Frontend Developer", "Data Analyst", "Product Manager"];
  const images = [
    "https://res.cloudinary.com/dilc7oqpu/image/upload/v1721681500/Innkeeper%20App/home-page/Image_1_qohe9y.svg",
    "https://res.cloudinary.com/dilc7oqpu/image/upload/v1721731820/Innkeeper%20App/home-page/Image_3_wcep7q.svg",
    "https://res.cloudinary.com/dilc7oqpu/image/upload/v1721731920/Innkeeper%20App/home-page/Property_1_Image_4_brbdoe.svg",
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 3000); // Change text and image every 3 seconds
    return () => clearInterval(interval);
  }, [texts.length]);

  return (
    <>
      <div className="hero-section">
        <Container>
          <Row>
            <Col md={6} className="hero-text">
              <h1>
                Become a <span> {texts[currentIndex]}</span> in 3 months
              </h1>
              <p>
                Launch your tech future: Become globally valuable with
                innkeeper. Seamless start, endless possibilities.
              </p>
              <Button style={{ backgroundColor: "#002CB0" }}>
                Start Learning
              </Button>
            </Col>
            <Col md={6} className="hero-image">
              <img
                src={images[currentIndex]}
                alt="Hero"
                className="dynamic-image"
              />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default HeroSection;
