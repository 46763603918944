import React from "react";
import TopNavbar from "../components/TopNavbar";
import HeroSection from "../components/LandingPage/HeroSection";
import CourseCard from "../components/LandingPage/CourseCards";
import Contact from "../components/LandingPage/Contact";
import Partners from "../components/LandingPage/Partners";
import TalentsCompany from "../components/LandingPage/TalentsCompany";
import Footer from "../components/Footer";
import Testimonials from "../components/LandingPage/Testimonials";
import FAQs from "../components/LandingPage/Faqs";
import WhyChooseUs from "../components/LandingPage/WhyChoose";

const Home = () => {
  return (
    <>
      
      <TopNavbar />
      <HeroSection />
      <Partners />
      <CourseCard />
      <WhyChooseUs />
      <FAQs />
      <Testimonials />
      <TalentsCompany />
      <Contact />
      <Footer />
    </>

  );
};

export default Home;
