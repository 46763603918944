import React from "react";
import Navbar from "../components/TopNavbar";
import ApplicationForm from "../components/ApplyNow/ApplicationForm";
import FAQs from "../components/LandingPage/Faqs";
import Testimonials from "../components/LandingPage/Testimonials"
import Footer from "../components/Footer";

const Apply = () => {
  return (
    <>
      <Navbar />
      <ApplicationForm />
      <FAQs />
      <Testimonials/>
      <Footer />
    </>
  );
};

export default Apply;
