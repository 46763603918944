import React, { useEffect, useState } from "react";
import axios from "axios";
import AOS from "aos";
import "aos/dist/aos.css";
import { Container, Card, Button, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStar as solidStar,
  // faStarHalfAlt as halfStar,
  faStar as emptyStar,
} from "@fortawesome/free-solid-svg-icons";
import "./coursecards.css";

const CourseCard = () => {
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    axios
      .get("/json/courses.json")
      .then((response) => setCourses(response.data))
      .catch((error) => console.error("Error fetching course data:", error));

    AOS.init({ duration: 1000 });
  }, []);

  const generateExcerpt = (title) => {
    return `${title} is a comprehensive course...`;
  };

  const renderRatingStars = (rating) => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(
        <FontAwesomeIcon
          key={i}
          icon={i <= rating ? solidStar : emptyStar}
          style={{
            color: i <= rating ? "gold" : "lightgray",
            fontSize: "10px",
          }}
        />
      );
    }
    return stars;
  };

  return (
    <>
      <div style={{ backgroundColor: "#f5f8f8", padding: "5rem 0" }}>
        <Container>
          <Row>
            <Col>
              <h4>Our Courses</h4>
              <p>
                Have you been thinking of courses to enroll in this year? We
                have you in mind!
              </p>
            </Col>
          </Row>
          <Row>
            {courses.map((course, index) => (
              <Col key={index} xs={12} sm={6} md={4} lg={3} data-aos="fade-up">
                <Card className="course-card">
                  <Card.Img
                    variant="top"
                    src={course.image}
                    className="course-image"
                  />
                  <Card.Body className="course-details">
                    <Card.Title className="course-title">
                      <h4>{course.title}</h4>
                    </Card.Title>
                    <div className="course-ratings">
                      {renderRatingStars(course.rating)}
                      <span id="reviews"> ({course.reviews} reviews)</span>
                    </div>
                    <div className="course-price-duration">
                      <span>₦{course.price}</span> *{" "}
                      <span id="duration">{course.duration}</span>
                    </div>
                    <div className="course-excerpt">
                      {generateExcerpt(course.title)}
                    </div>
                    <hr />
                    {course.modes.map((mode, modeIndex) => (
                      <Button
                        key={modeIndex}
                        variant="outline-primary"
                        className="learning-mode-button"
                      >
                        {mode}
                      </Button>
                    ))}
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </>
  );
};

export default CourseCard;
