import React, { useState } from "react";
import { Form, Button, Container, Row, Col, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import "./application-form.css";

function ApplicationForm() {
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    dob: "",
    gender: "",
    location: "",
    age: "",
    course: "",
    learningMode: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isGenderOpen, setIsGenderOpen] = useState(false);
  const [isAgeOpen, setIsAgeOpen] = useState(false);
  const [isLearningModeOpen, setIsLearningModeOpen] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      return;
    }

    setValidated(true);
    setIsSubmitting(true); // Show spinner while submitting

    const googleScriptURL =
      "https://script.google.com/macros/s/AKfycbxFFwIxgTN0qxbqM0_HerNcdUsbkieVsZzQ9eMiJ7y_fbQIlGCIzZk36m0XrrndsY7h/exec";

    try {
      const response = await fetch(googleScriptURL, {
        method: "POST",
        body: new URLSearchParams(formData),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });

      const result = await response.text();
      if (result.includes("success")) {
        Swal.fire({
          title: "Success!",
          text: "Your application has been received; kindly check your mail for our response.",
          icon: "success",
          confirmButtonText: "OK",
        }).then(() => {
          setFormData({
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            dob: "",
            gender: "",
            location: "",
            age: "",
            course: "",
            learningMode: "",
          });
          setValidated(false);
          form.reset(); // Reset the form fields
        });
      } else {
        Swal.fire({
          title: "Submission Failed",
          text: "There was a problem with your submission.",
          icon: "error",
          confirmButtonText: "Try Again",
        });
      }
    } catch (error) {
      console.error("Error:", error);
      Swal.fire({
        title: "Error!",
        text: "Form submission failed due to a network error.",
        icon: "error",
        confirmButtonText: "Try Again",
      });
    } finally {
      setIsSubmitting(false); // Hide spinner after submission
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <Container fluid className="bg-light p-5 application-form">
      <Row className="justify-content-center">
        <Col md={6} className="p-4">
          <img
            src="https://res.cloudinary.com/dilc7oqpu/image/upload/v1727442591/Innkeeper%20App/facility/students.jpg"
            alt="Application form illustration"
            className="img-fluid"
          />
        </Col>
        <Col md={6} className="p-4">
          <h2>Application Form</h2>
          <p>
            We’d love you to show your interest by applying here for free.
            Please fill out this form.
          </p>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row>
              <Col md={6} className="mb-4">
                <Form.Group controlId="formFirstName">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    placeholder="Enter first name"
                    className="custom-opacity"
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter your first name.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6} className="mb-4">
                <Form.Group controlId="formLastName">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    placeholder="Enter last name"
                    className="custom-opacity"
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter your last name.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6} className="mb-4">
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    required
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="you@gmail.com"
                    className="custom-opacity"
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter a valid email address.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6} className="mb-4">
                <Form.Group controlId="formBasicPhone">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control
                    required
                    type="tel"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    placeholder="+2340000000030"
                    className="custom-opacity"
                  />
                  <Form.Control.Feedback type="invalid">
                    Enter a valid Number.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6} className="mb-4">
                <Form.Group controlId="formBasicDOB">
                  <Form.Label>Date of Birth</Form.Label>
                  <Form.Control
                    type="date"
                    name="dob"
                    value={formData.dob}
                    onChange={handleChange}
                    required
                    className="custom-opacity"
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter your date of birth.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6} className="mb-4">
                <Form.Group controlId="formBasicGender">
                  <Form.Label>Gender</Form.Label>
                  <div className="custom-select">
                    <Form.Control
                      as="select"
                      name="gender"
                      value={formData.gender}
                      onChange={handleChange}
                      required
                      className="custom-opacity"
                      onClick={() => setIsGenderOpen(!isGenderOpen)}
                    >
                      <option value="">Select gender</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </Form.Control>
                    <FontAwesomeIcon
                      icon={faChevronDown}
                      className={`select-arrow ${isGenderOpen ? "rotate" : ""}`}
                    />
                  </div>
                  <Form.Control.Feedback type="invalid">
                    Please select your gender.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6} className="mb-4">
                <Form.Group controlId="formBasicLocation">
                  <Form.Label>Location</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    name="location"
                    value={formData.location}
                    onChange={handleChange}
                    placeholder="e.g. Lagos, Nigeria"
                    className="custom-opacity"
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter your location.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6} className="mb-4">
                <Form.Group controlId="formBasicAge">
                  <Form.Label>Age Range</Form.Label>
                  <div className="custom-select">
                    <Form.Control
                      as="select"
                      name="age"
                      value={formData.age}
                      onChange={handleChange}
                      required
                      className="custom-opacity"
                      onClick={() => setIsAgeOpen(!isAgeOpen)}
                    >
                      <option value="">Select age range</option>
                      <option value="under 18">Under 18</option>
                      <option value="18-24">18-24</option>
                      <option value="25-34">25-34</option>
                      <option value="35-44">35-44</option>
                      <option value="45+">45+</option>
                    </Form.Control>
                    <FontAwesomeIcon
                      icon={faChevronDown}
                      className={`select-arrow ${isAgeOpen ? "rotate" : ""}`}
                    />
                  </div>
                  <Form.Control.Feedback type="invalid">
                    Please select your age range.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6} className="mb-4">
                <Form.Group controlId="formBasicCourse">
                  <Form.Label>Course of Interest</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    name="course"
                    value={formData.course}
                    onChange={handleChange}
                    placeholder="e.g. Web Development"
                    className="custom-opacity"
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter a course of interest.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6} className="mb-4">
                <Form.Group controlId="formBasicLearningMode">
                  <Form.Label>Learning Mode</Form.Label>
                  <div className="custom-select">
                    <Form.Control
                      as="select"
                      name="learningMode"
                      value={formData.learningMode}
                      onChange={handleChange}
                      required
                      className="custom-opacity"
                      onClick={() => setIsLearningModeOpen(!isLearningModeOpen)}
                    >
                      <option value="">Select mode</option>
                      <option value="online">Online</option>
                      <option value="offline">Offline</option>
                    </Form.Control>
                    <FontAwesomeIcon
                      icon={faChevronDown}
                      className={`select-arrow ${
                        isLearningModeOpen ? "rotate" : ""
                      }`}
                    />
                  </div>
                  <Form.Control.Feedback type="invalid">
                    Please select a learning mode.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <Button
              variant="primary"
              type="submit"
              disabled={isSubmitting}
              className="btn-lg btn-block"
            >
              {isSubmitting ? (
                <>
                  <Spinner animation="border" size="sm" /> Submitting...
                </>
              ) : (
                "Submit"
              )}
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default ApplicationForm;
