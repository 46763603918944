import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";

const teamMembers = [
  {
    name: "Damilola Obidairo",
    title: "Executive Director",
    imageUrl:
      "https://res.cloudinary.com/dilc7oqpu/image/upload/v1727438932/Innkeeper%20App/partners/DAMILOLA_OBIDAIRO_jo1cgk.png",
    alt: "Damilola Obidairo",
    linkedin: "https://www.linkedin.com/in/damilolaobidairo/",
  },
  {
    name: "Olarewaju Sunkanmi",
    title: "CEO, Innkeeper",
    imageUrl:
      "https://res.cloudinary.com/dilc7oqpu/image/upload/v1727438959/Innkeeper%20App/partners/Sunkanmi_Olarewaju_ckfi2h.png ",
    alt: "Olarewaju Sunkanmi",
    linkedin: "https://www.linkedin.com/in/olarewaju-olasunkanmi/",
  },
  {
    name: "Omowunmi Adekoya",
    title: "Non-Executive Director",
    imageUrl:
      "https://res.cloudinary.com/dilc7oqpu/image/upload/v1727438948/Innkeeper%20App/partners/Omowunmi_Adekoya_vazzoa.png",
    alt: "Omowunmi Adekoya",
    linkedin: "https://www.linkedin.com/in/omowunmiobidairo/",
  },
];

const OurTeam = () => {
  return (
    <div style={{ backgroundColor: "#fff", padding: "50px 0" }}>
      <Container>
        <Row className="text-center mb-5">
          <Col>
            <h2>Meet our team</h2>
            <p style={{ color: "#7A7A7A" }}>
              We are passionate about impacting lives
            </p>
          </Col>
        </Row>
        <Row className="text-center">
          {teamMembers.map((member, index) => (
            <Col key={index} md={4} className="mb-4">
              <Image
                src={member.imageUrl}
                roundedCircle
                style={{
                  width: "150px",
                  height: "150px",
                  objectFit: "cover",
                }}
                alt={member.name}
              />
              <h5 className="mt-3">{member.name}</h5>
              <a
                href={member.linkedin}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: "#6E44FF",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textDecoration: "none",
                }}
              >
                {member.title}
                <FontAwesomeIcon
                  icon={faLinkedin}
                  size="lg"
                  style={{ marginLeft: "10px" }}
                />
              </a>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default OurTeam;
