import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./learning-track.css";

const LearningApproachSection = () => {
  return (
    <Container className="learning-approach-section pt-5 px-5 align-items-center">
      <Row>
        <Col md={6}>
          <h3 className="my-2">
            Our <span style={{ color: "#002CB0" }}>Approach</span> to Learning
            is <span id="hero-text">Blended</span>
          </h3>
          <p>
            We combine self-paced materials with instructor-led sessions for
            better learning experience and impact.
          </p>
          <ul className="custom-bullets">
            <li>Learn at your pace with our self-paced learning materials.</li>
            <li>Blend self-directed learning with instructor-led sessions.</li>
            <li>Personalized learning experience.</li>
          </ul>
        </Col>
        <Col md={6}>
          <img
            src="https://res.cloudinary.com/dilc7oqpu/image/upload/v1727947415/aboutpg.jpg"
            alt="Learning Track"
            className="img-fluid"
          />
        </Col>
      </Row>
    </Container>
  );
};

export default LearningApproachSection;
