import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../assets/css/footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faEnvelope,
  faPhoneAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faTwitter,
  faLinkedin,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";

// Helper function to get current date and time
const getCurrentDateTime = () => {
  const now = new Date();

  // Format date
  const dateFormatter = new Intl.DateTimeFormat("en-GB", {
    weekday: "long",
    day: "numeric",
    month: "long",
    year: "numeric",
  });
  const date = dateFormatter.format(now);

  // Add ordinal suffix to date
  const day = now.getDate();
  const ordinalSuffix = (day) => {
    if (day > 3 && day < 21) return "th";
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };
  const formattedDate = date.replace(day, `${day}${ordinalSuffix(day)}`);

  // Format time
  const time = now.toLocaleTimeString();

  return { formattedDate, time };
};

const Footer = () => {
  const [dateTime, setDateTime] = useState(getCurrentDateTime());

  useEffect(() => {
    const timer = setInterval(() => {
      setDateTime(getCurrentDateTime());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <footer className="footer-container">
      {/* Basic Section */}
      <div className="section">
        <h3 className="heading">Basic</h3>
        <ul className="list">
          <li className="list-item">
            <Link to="/about"> About us</Link>
          </li>
          <li className="list-item">
            <Link to="/contact">Contact us </Link>
          </li>
          <li className="list-item">
            <a href="#privacy">Privacy Policy</a>
          </li>
          <li className="list-item">
            <a href="#terms">Terms & Conditions</a>
          </li>
          <li className="list-item">
            <a href="#support">Support Center</a>
          </li>
          {/* Add more items as needed */}
        </ul>
      </div>

      {/* Courses Offered Section */}
      <div className="section">
        <h3 className="heading">Courses Offered</h3>
        <ul className="list">
          <li className="list-item">
            <Link to="/courses/frontend-development">
              Frontend Development{" "}
            </Link>
          </li>
          <li className="list-item">
            <Link to="/courses/digital-marketing">Digital Marketing </Link>
          </li>
          <li className="list-item">
            <Link to="/courses/product-design">UI/UX Design </Link>
          </li>
          <li className="list-item">
            <Link to="/courses/data-analysis">Data Analysis</Link>
          </li>
          <li className="list-item">
            <Link to="/courses/cybersecurity">Cybersecurity</Link>
          </li>
          {/* Add more courses */}
        </ul>
      </div>

      {/* Contact Us Section */}
      <div className="section">
        <h3 className="heading">Contact Us</h3>
        <ul className="list">
          <li className="list-item">
            <FontAwesomeIcon icon={faMapMarkerAlt} className="fa-icon" />
            <a
              href="https://www.google.com/maps/search/?api=1&query=Innkeeper+Hackerspace,+11+Colin+Onabule+Crescent,+Diamond+Estate,+Magodo+Phase+2"
              target="_blank"
              rel="noopener noreferrer"
            >
              Innkeeper Hackerspace, 11b Colin Onabule Crescent, Diamond Estate,
              Magodo Phase 2
            </a>
          </li>
          <li className="list-item">
            <FontAwesomeIcon icon={faEnvelope} className="fa-icon" />
            <a href="mailto:Academy@innkeeper.work">academy@innkeeper.work</a>
          </li>
          <li className="list-item">
            <FontAwesomeIcon icon={faPhoneAlt} className="fa-icon" />
            <a href="tel:2348137011735">+234 813 701 1735</a>
          </li>
        </ul>
      </div>

      {/* Social Media Section */}
      <div className="section">
        <h3 className="heading">Social Media</h3>
        <ul className="list">
          <li className="list-item">
            <FontAwesomeIcon icon={faFacebook} className="fa-icon" />
            <a
              href="https://www.facebook.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Facebook
            </a>
          </li>
          <li className="list-item">
            <FontAwesomeIcon icon={faTwitter} className="fa-icon" />
            <a
              href="https://www.twitter.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Twitter
            </a>
          </li>
          <li className="list-item">
            <FontAwesomeIcon icon={faInstagram} className="fa-icon" />
            <a
              href="https://www.instagram.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Instagram
            </a>
          </li>
          <li className="list-item">
            <FontAwesomeIcon icon={faLinkedin} className="fa-icon" />
            <a
              href="https://www.linkedin.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Linkedin
            </a>
          </li>
          {/* Add more social media links */}
        </ul>
      </div>

      {/* Copyright Section */}
      <div className="copyright">
        <strong>
          &copy; {dateTime.formattedDate} Innkeeper.work. <br />
          All rights reserved. {""}
          {dateTime.time}
        </strong>
      </div>
    </footer>
  );
};

export default Footer;
