import React from "react";
import Navbar from "../../components/TopNavbar";
import Hero from "../../components/Courses/Hero";
import LearningObjectives from "../../components/Courses/LearningObjectives";
import AmazingMoments from "../../components/Courses/AmazingMoment";
import Pricing from "../../components/Courses/PaymentCard";
import Footer from "../../components/Footer";

const ProductDesign = () => {
  const productDesignObjectives = [
    "Understand the core principles of UI and UX design.",
    "Conduct user research to identify user needs and pain points.",
    "Create user personas and user flows to map out the user journey.",
    "Design wireframes, prototypes, and mockups for user interfaces.",
    "Apply visual design principles such as typography, colour theory, and layout to create user-friendly interfaces.",
    "Utilize design tools like Figma or Adobe XD to create high-fidelity prototypes.",
    "Test and iterate your designs based on user feedback.",
  ];

  const pricingPlans = [
    {
      title: "Best Value",
      description: "One-time payment",
      price: "₦250,000",
      features: [
        "Access to all classes",
        "Access to facilitators live classes",
        "Learn and get internship slots immediately",
        "Get mentorship for three months",
        "Learn in a conclusive environment",
      ],
      buttonStyle: { backgroundColor: "#002CB0", border: "none" },
    },
    {
      title: "Flexible Plan",
      description: "Pay in batches",
      price: "₦130,000 x 2",
      features: [
        "Payment in the 1st and 7th week",
        "Access to facilitators live classes",
        "Learn and get internship slots immediately",
        "Get mentorship for three months",
        "Learn in a conclusive environment",
      ],
      buttonStyle: {
        backgroundColor: "transparent",
        border: "1px solid #002CB0",
        color: "#002CB0",
      },
    },
    {
      title: "Flexible Plan",
      description: "Pay in batches",
      price: "₦100,000 x 3",
      features: [
        "Payment in the 1st, 5th and 10th week",
        "Access to facilitators live classes",
        "Learn and get internship slots immediately",
        "Get mentorship for three months",
        "Learn in a conclusive environment",
      ],
      buttonStyle: {
        backgroundColor: "transparent",
        border: "1px solid #002CB0",
        color: "#002CB0",
      },
    },
    {
      title: "Learn Now Pay Later",
      description: "Start paying a month after training",
      price: "Free",
      features: [
        "₦10,000 Admission Fee",
        "6 months repayment + 25% interest",
        "12 months repayment + 35% interest",
        "Access to facilitators live classes",
        "Get internship placements",
        "Get mentorship for three months",
        "Learn in a conclusive environment",
      ],
      buttonStyle: { backgroundColor: "#002CB0", border: "none" },
    },
  ];
  return (
    <>
      <Navbar />
      {/* Hero Component */}
      <Hero
        title="Be a Product Designer in 3 months"
        description="This course equips you with the skills and knowledge to design user-centered interfaces (UI) and user experiences (UX) for web and mobile applications."
        buttonText="Apply here"
        buttonStyle={{ backgroundColor: "#002CB0" }}
        imageSrc="https://res.cloudinary.com/dilc7oqpu/image/upload/v1723207416/Innkeeper%20App/courses/product-design-course_oj2jvh.png"
        imageAlt="Product Design"
      />
      {/* Learning Objectives Component */}
      <LearningObjectives
        title="Learning Objectives"
        objectives={productDesignObjectives}
        imageSrc="https://res.cloudinary.com/dilc7oqpu/image/upload/v1723208259/Innkeeper%20App/courses/learning-objective-img_dlz5vo.png"
        imageAlt="Learning Objectives"
        iconSrc="https://res.cloudinary.com/dilc7oqpu/image/upload/v1723215832/Innkeeper%20App/courses/tick-circle_l1i52j.svg"
      />
      {/* Amazing moments components */}
      <AmazingMoments
        title="Watch some amazing moments with us"
        description="Our Facilitators and students have amazing moments together as they delve into design and its intricacies."
        videoSrc="https://www.youtube.com/embed/AnXAkEpUQV0"
        videoTitle="Meet Ella Innkeeper Fellow"
      />

      {/* Pricing Plans */}
      <Pricing
        title="Amazing payment plans for you"
        description="Our payment options have an amazing impact on how you learn"
        plans={pricingPlans}
        iconSrc="https://res.cloudinary.com/dilc7oqpu/image/upload/v1723215832/Innkeeper%20App/courses/tick-circle_l1i52j.svg"
      />
      <Footer />
    </>
  );
};

export default ProductDesign;
