import React from "react";
import { Container, Row, Col, Button, Card } from "react-bootstrap";
import "./paymentcard.css";

const Pricing = ({ title, description, plans, iconSrc }) => {
  return (
    <section className="pricing-section">
      <Container className="text-center my-5">
        <h4>{title}</h4>
        <p>{description}</p>
        <Row>
          {plans.map((plan, index) => (
            <Col md={3} key={index}>
              <Card className="pricing-card">
                <Card.Body>
                  <h6>{plan.title}</h6>
                  <p id="desc">{plan.description}</p>
                  <h3>{plan.price}</h3>
                  <ul className="text-start">
                    {plan.features.map((feature, i) => (
                      <li key={i}>
                        <img src={iconSrc} alt="Tick" className="list-icon" />
                        {feature}
                      </li>
                    ))}
                  </ul>
                  <Button style={plan.buttonStyle}>Apply now</Button>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default Pricing;
