import React, { useState } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import "./contact.css";
import "../../assets/css/styleguide.css";

function Contact() {
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };

  return (
    <div className="contact-background">
      <Container className="contact-container">
        <Row className="justify-content-center">
          <Col md={8}>
            <Row>
              <Col md={6} className="mb-4 mb-md-0">
                <div className="ball p-4">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3963.2423172245108!2d3.3671399999999885!3d6.61678869999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b939fa4a2bec9%3A0x340b29b74e68299b!2sInnkeeper%20Hackerspace%20(Innkeeper%20Talent%20Accelerator)!5e0!3m2!1sen!2sus!4v1718719368845!5m2!1sen!2sus"
                    width="100%"
                    height="400"
                    allowFullScreen
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                    title="Responsive Google Map"
                    style={{ borderRadius: "10px" }}
                  ></iframe>
                </div>
              </Col>
              <Col md={6} className="p-4">
                <h2 className="word">Contact Us</h2>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  <Row>
                    <Col md={6}>
                      <Form.Group controlId="formFirstName" className="mb-3">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          placeholder="Enter first name"
                          className="custom-opacity"
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter your first name.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId="formLastName" className="mb-3">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          placeholder="Enter last name"
                          className="custom-opacity"
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter your last name.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Form.Group controlId="formBasicEmail" className="mb-4">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control
                      required
                      type="email"
                      placeholder="Enter email"
                      className="custom-opacity"
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter a valid email address.
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group controlId="formBasicMessage" className="mb-4">
                    <Form.Label>Message</Form.Label>
                    <Form.Control
                      required
                      as="textarea"
                      rows={3}
                      className="custom-opacity"
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter a message.
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Button type="submit" className="w-100  contact-btn">
                    Submit
                  </Button>
                </Form>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Contact;
