import React from "react";
import WhyChooseUsCard from "./WhyChooseItem";
import "./whychoose.css";
const learningOptions = [
  {
    title: "Blended Learning",
    description:
      "Learn at your own pace with online content and instructor-led sessions. Get the best of both worlds!.",
    icon: "https://res.cloudinary.com/dilc7oqpu/image/upload/v1721681499/Innkeeper%20App/home-page/Frame_82_wihx3g.svg",
    link: "src/components/WhyChooseUs/Blendedlearning.jsx",
    arrow:
      "https://res.cloudinary.com/dilc7oqpu/image/upload/v1723630411/Innkeeper%20App/home-page/arrow-right_zmrxpm.svg",
    styleName: "blendedLearning",
  },
  {
    title: "Global Knowledge",
    description:
      "Master global tech principle with regional tailor content, delivered by facilitators who understand your local context.",
    icon: "https://res.cloudinary.com/dilc7oqpu/image/upload/v1721681499/Innkeeper%20App/home-page/Frame_82_1_waykhn.svg",
    link: "src/components/WhyChooseUs/Instructorled.jsx",
    arrow:
      "https://res.cloudinary.com/dilc7oqpu/image/upload/v1723630411/Innkeeper%20App/home-page/arrow-right_zmrxpm.svg",
    styleName: "instructorLedSessions",
  },
  {
    title: "Flexible Payment Plans",
    description:
      "choose a payment plan that fit your budget. We make quality tech education accessible.",
    icon: "https://res.cloudinary.com/dilc7oqpu/image/upload/v1721681501/Innkeeper%20App/home-page/Frame_82_2_esjikq.svg",
    link: "src/components/WhyChooseUs/Peercollaboration.jsx",
    arrow:
      "https://res.cloudinary.com/dilc7oqpu/image/upload/v1723630411/Innkeeper%20App/home-page/arrow-right_zmrxpm.svg",
    styleName: "peerCollaboration",
  },
  {
    title: "Expert Mentorship",
    description:
      "Gain invaluable guidance from experienced professionals and connect with a supprotive community of fellow  learners.",
    icon: "https://res.cloudinary.com/dilc7oqpu/image/upload/v1721681499/Innkeeper%20App/home-page/Frame_82_3_qwpij9.svg",
    link: "src/components/WhyChooseUs/Globallycurated.jsx",
    arrow:
      "https://res.cloudinary.com/dilc7oqpu/image/upload/v1723630411/Innkeeper%20App/home-page/arrow-right_zmrxpm.svg",
    styleName: "globallyCurated",
  },
];

const WhyChooseUs = () => {
  return (
    <>
      <div className="container-fluid" style={{ backgroundColor: "#F5F8F8" }}>
        <div className="container py-5 pb-5">
          <header className="AppHeader my-5">
            <h1>Why Choose Us</h1>
            <p>
              At Innkeeper, we offer a unique learning experience that empowers
              talent to thrive.
            </p>
          </header>

          <div className="row featureGrid">
            {learningOptions.map((Option, index) => (
              <div
                className="col-sm-12 col-md-6 col-lg-3 d-flex align-items-stretch"
                key={index}
              >
                <WhyChooseUsCard
                  title={Option.title}
                  description={Option.description}
                  icon={Option.icon}
                  link={Option.link}
                  arrow={Option.arrow}
                  styleName={Option.styleName}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default WhyChooseUs;
