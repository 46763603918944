// Hero.js
import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";

const Hero = ({
  title,
  fancy,
  description,
  buttonText,
  buttonStyle,
  imageSrc,
  imageAlt,
}) => {
  return (
    <section className="hero-section">
      <Container>
        <Row className="gy-4">
          <Col md={6}>
            <h3>
              {title} <span className="fancy-text">{fancy}</span>
            </h3>
            <p>{description}</p>
            <Button style={buttonStyle}>{buttonText}</Button>
          </Col>
          <Col md={6}>
            <img
              src={imageSrc}
              height={200}
              alt={imageAlt}
              className="img-fluid"
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Hero;
