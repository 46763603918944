import React, { useEffect, useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import { useMediaQuery } from "react-responsive";
import axios from "axios";
import "./testimonials.css";

const Testimonials = () => {
  const [testimonialsData, setTestimonialsData] = useState([]);

  // Define breakpoints for different screen sizes
  const isSmallScreen = useMediaQuery({ query: "(max-width: 576px)" });
  const isMediumScreen = useMediaQuery({
    query: "(min-width: 577px) and (max-width: 768px)",
  });

  // Determine the number of items per slide based on screen size
  const itemsPerSlide = isSmallScreen ? 1 : isMediumScreen ? 2 : 3;

  // Function to chunk the testimonials data into groups of itemsPerSlide
  const chunkedTestimonialsData = (data, size) => {
    const chunks = [];
    for (let i = 0; i < data.length; i += size) {
      chunks.push(data.slice(i, i + size));
    }
    return chunks;
  };

  useEffect(() => {
    const fetchTestimonials = async () => {
      try {
        const response = await axios.get("/json/testimonialsData.json");
        setTestimonialsData(response.data);
      } catch (error) {
        console.error("Error fetching testimonials data:", error);
      }
    };

    fetchTestimonials();
  }, []);

  const testimonialChunks = chunkedTestimonialsData(
    testimonialsData,
    itemsPerSlide
  );

  return (
    <div className="container-fluid py-5 testimonials">
      <div className="container py-5">
        <div className="testimonialheader">
          <h4>
            Our Alumni Stories - Real Results, Real People: Unleash Your Inner
            Potentials!
          </h4>
          <p>
            Read what our trained talents are saying about their experience and
            be informed of your decision to join our community.
          </p>
        </div>
        <Carousel interval={3000}>
          {testimonialChunks.map((chunk, chunkIndex) => (
            <Carousel.Item key={chunkIndex}>
              <div className="d-flex justify-content-around">
                {chunk.map((testimonial, testimonialIndex) => (
                  <div key={testimonialIndex} className="testimonial">
                    <div className="d-flex">
                      <img
                        src={testimonial.image}
                        alt={testimonial.name}
                        className="testimonialImg"
                      />
                      <div className="nameRole">
                        <h3>{testimonial.name}</h3>
                        <p>{testimonial.role}</p>
                      </div>
                    </div>
                    <div>
                      <p className="testimonialText">
                        {testimonial.testimonial}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default Testimonials;
