import React from "react";
import Navbar from "../../components/TopNavbar";
import Hero from "../../components/Courses/Hero";
import LearningObjectives from "../../components/Courses/LearningObjectives";
import AmazingMoments from "../../components/Courses/AmazingMoment";
import Pricing from "../../components/Courses/PaymentCard";
import Faqs from "../../components/LandingPage/Faqs";
import Testimonials from "../../components/LandingPage/Testimonials";
import Contact from "../../components/LandingPage/Contact";
import Footer from "../../components/Footer";

const DataAnalysis = () => {
  const dataAnalysisObjectives = [
    "Understand the core principles of data analysis.",
    "Learn to collect, clean, and analyze data.",
    "Master data visualization techniques.",
    "Gain proficiency in tools like Excel, SQL, and Python.",
    "Conduct statistical analysis and interpret results.",
    "Prepare for industry-recognized data analysis certifications.",
  ];

  const dataAnalysisPlans = [
    {
      title: "Best Value",
      description: "One-time payment",
      price: "₦250,000",
      features: [
        "Access to all classes",
        "Access to facilitators live classes",
        "Learn and get internship slots immediately",
        "Get mentorship for three months",
        "Learn in a conclusive environment",
      ],
      buttonStyle: { backgroundColor: "#002CB0", border: "none" },
    },
    {
      title: "Flexible Plan",
      description: "Pay in batches",
      price: "₦130,000 x 2",
      features: [
        "Payment in the 1st and 7th week",
        "Access to facilitators live classes",
        "Learn and get internship slots immediately",
        "Get mentorship for three months",
        "Learn in a conclusive environment",
      ],
      buttonStyle: {
        backgroundColor: "transparent",
        border: "1px solid #002CB0",
        color: "#002CB0",
      },
    },
    {
      title: "Flexible Plan",
      description: "Pay in batches",
      price: "₦100,000 x 3",
      features: [
        "Payment in the 1st, 5th and 10th week",
        "Access to facilitators live classes",
        "Learn and get internship slots immediately",
        "Get mentorship for three months",
        "Learn in a conclusive environment",
      ],
      buttonStyle: {
        backgroundColor: "transparent",
        border: "1px solid #002CB0",
        color: "#002CB0",
      },
    },
    {
      title: "Learn Now Pay Later",
      description: "Start paying a month after training",
      price: "Free",
      features: [
        "₦10,000 Admission Fee",
        "6 months repayment + 25% interest",
        "12 months repayment + 35% interest",
        "Access to facilitators live classes",
        "Get internship placements",
        "Get mentorship for three months",
        "Learn in a conclusive environment",
      ],
      buttonStyle: { backgroundColor: "#002CB0", border: "none" },
    },
  ];

  return (
    <>
      <Navbar />
      {/* Hero Component */}
      <Hero
        title="Learn"
        fancy="Data Analysis"
        description="This course teaches you the fundamentals of data analysis, including data collection, cleaning, visualization, and statistical analysis."
        buttonText="Enroll now"
        buttonStyle={{ backgroundColor: "#002CB0" }}
        imageSrc="https://res.cloudinary.com/dilc7oqpu/image/upload/v1727435981/Innkeeper%20App/facility/training-session.jpg"
        imageAlt="Data Analysis"
      />

      {/* Learning Objectives Component */}
      <LearningObjectives
        title="Data Analysis Objectives"
        objectives={dataAnalysisObjectives}
        imageSrc="https://res.cloudinary.com/dilc7oqpu/image/upload/v1727435981/Innkeeper%20App/facility/training-session.jpg"
        imageAlt="Data Analysis Objectives"
        iconSrc="https://res.cloudinary.com/dilc7oqpu/image/upload/v1723215832/Innkeeper%20App/courses/tick-circle_l1i52j.svg"
      />

      {/* Amazing moments components */}
      <AmazingMoments
        title="Join our data analysis journey"
        description="Explore the exciting world of data analysis with our expert instructors and fellow students."
        videoSrc="https://www.youtube.com/embed/Fwx2xLv-sFc"
        videoTitle="Data Analysis Journey"
      />

      {/* Pricing Plans */}
      <Pricing
        title="Data Analysis Payment Plans"
        description="Choose the plan that suits your needs"
        plans={dataAnalysisPlans}
        iconSrc="https://res.cloudinary.com/dilc7oqpu/image/upload/v1723215832/Innkeeper%20App/courses/tick-circle_l1i52j.svg"
      />
      <Faqs/>
<Testimonials/>
<Contact/>
      <Footer />
    </>
  );
};

export default DataAnalysis;
